<template>
  <div class="budengyu">
    <v-app-bar app scroll-off-screen height="50" class="sub-topbar" style="background: none" color="white">
      <div :class="classname">
        <div class="d-flex justify-space-between align-center">
          <div class="align-center flex-1 d-flex">
            <router-link to="/">
              <img height="30" src="@/assets/images/logo.png" alt="">
           
            </router-link>
            <ul class="navbar-nav navigation-navbar flex-row d-md-flex d-none justify-start flex-1 ml-6">
              <li class="nav-item mr-6">
                <router-link :class="activeLink == '/solution'
        ? 'text-body-1 primary--text'
        : 'text--primary text-body-1'
        " to="/solution">{{ $t("解决方案") }}</router-link>
              </li>
              <li class="nav-item mr-6">
                <router-link :class="activeLink == '/recruit'
        ? 'text-body-1 primary--text'
        : 'text--primary text-body-1'
        " to="/recruit">{{ $t("招募供应商") }}</router-link>
              </li>
              <li class="nav-item ">
                <router-link :class="activeLink == '/template-index'
        ? 'text-body-1 primary--text'
        : 'text--primary text-body-1'
        " to="/template-index">{{ $t("模版市场") }}</router-link>
              </li>

            </ul>
          </div>
          <ul class="navbar-nav navigation-navbar flex-row justify-end align-center d-flex">

            <li class="nav-item d-none d-md-block" v-if="obj == ''">
              <v-btn class="primary rounded-pill" depressed @click="zhuce()">{{
        $t("店铺开通")
      }}</v-btn>
            </li>
            <li class="nav-item mx-4" v-if="$store.state.USERINFO == null">

              <a :href="`${$store.state.locationOrigin}/manger_admin`" target="_blank">

                <v-btn class="rounded-pill primary--text" color="rgba(115, 49, 255, 0.10)" depressed>{{
        $t("登录") }}</v-btn>
              </a>
            </li>
            <v-menu open-on-hover bottom offset-y v-else nudge-left="50">
              <template v-slot:activator="{ on, attrs }">
                <li class="nav-item d-flex align-center mx-4" v-bind="attrs" v-on="on">
                  <v-avatar size="30" v-bind="attrs" v-on="on">
                    <img :src="$store.state.USERINFO.avatar" alt="alt">
                  </v-avatar>
                </li>
              </template>
              <v-list class="px-4">
                <a :href="`${$store.state.locationOrigin}/manger_admin`" target="_blank">
                  <v-list-item class="cursor  px-0">

                    <v-icon color="#333">mdi-store-outline</v-icon>
                    <span class="ml-2 ">{{ $t('小店管理') }}</span>

                  </v-list-item>
                </a>
                <v-divider></v-divider>
                <router-link to="/user-index">
                  <v-list-item class="cursor d-flex align-center px-0">
                    <v-icon color="#333">mdi-tshirt-crew-outline</v-icon>
                    <span class="ml-2 ">{{ $t('模版中心') }}</span>
                  </v-list-item>
                </router-link>
                <v-divider></v-divider>
                <v-list-item class="cursor d-flex align-center px-0">
                  <v-avatar size="24">
                    <img :src="$store.state.USERINFO.avatar" alt="alt">
                  </v-avatar>
                  <span class="ml-2 ">{{ $store.state.USERINFO.nickname }}</span>
                </v-list-item>
                <v-list-item class="cursor d-flex align-center px-0" @click="logout">
                  <v-icon color="#333">mdi-logout</v-icon>
                  <span class="ml-2 ">{{ $t("退出登录") }}</span>
                </v-list-item>

              </v-list>
            </v-menu>


            <v-menu open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <li class="nav-item d-flex align-center" v-bind="attrs" v-on="on">
                  <v-avatar size="30" v-bind="attrs" v-on="on">
                    <img :src="langdef.img" alt="alt">
                  </v-avatar>
                </li>
              </template>
              <v-list>
                <v-list-item class="cursor d-flex align-center" v-for="(item, index) in langList" :key="index"
                  @click="langChang(item)">
                  <div>
                    <img :src="item.img" width="24" alt="">

                  </div>
                  <span class="ml-2 text--primary text-body-1">{{
        item.name
      }}</span>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y >
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="30" v-bind="attrs" v-on="on" class="d-flex d-md-none ml-3">mdi-menu</v-icon>
              
              </template>
              <v-list>
                <ul class="px-4">
                  <li class="text-center py-2">
                    <router-link :class="activeLink == '/solution'
        ? 'text-body-1 primary--text text-center'
        : 'text-center text--primary text-body-1'
        " to="/solution">{{ $t("解决方案") }}</router-link>
                  </li>

                  <li class="text-center py-2">
                    <router-link :class="activeLink == '/recruit'
        ? 'text-body-1 primary--text text-center'
        : 'text-center text--primary text-body-1'
        " to="/recruit">{{ $t("招募供应商") }}</router-link>
                  </li>
                  <li class="text-center py-2" v-if="obj == ''">
                    <v-btn class="primary rounded-pill" depressed @click="zhuce()">{{ $t("立即免费开通") }}</v-btn>
                  </li>

                </ul>
              </v-list>
            </v-menu>
          </ul>

        </div>
      </div>
    </v-app-bar>

  </div>
</template>
<script>
export default {
  name: "BaseAppBar",
  data() {
    return {
      activeLink: "/",
      userinfos: "",
      classname: "container px-0",
      langdef: "",
      langList: [
        {
          name: "中文",
          langsign: "zh-CN",
          img: require("@/assets/images/recruit/guoqi1.png"),
        },
        {
          name: "English",
          langsign: "en",
          img: require("@/assets/images/recruit/en.png"),
        },
      ],
      obj: "",
    };
  },
  created() {
    let objs = {
      "id": "11",
      "username": "895507564@qq.com",
      "nickname": "895507564@qq.com",
      "avatar": "",
      "mobile": "18789895858",
      "email": "895507564@qq.com",
      "status": "1",
      "is_admin": "1",
      "auth_group_arr": [
        "2"
      ],
      "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiJxc3MiLCJpYXQiOjE2OTA1MTQ3MjMsIm5iZiI6MTY5MDUxNDcyMywiZXhwIjoxNjkxODEwNzIzLCJ1aWQiOiIxMSIsImlwIjoiMTkyLjE2OC4zMS4xNTEifQ.ckFXeSIBjrn-D6MW0yu-f3vyLtOduKGnHpGcAHfOLt_sCsFR7YMe2NLFWCcoulNoIqGmhSfUTFq6JomUjZ6HzA",
      "admin_id": "11",
      "shop_id": "1012",
      "usable_text_trans": "319",
      "usable_img_trans": "390"
    }
    //  localStorage.setItem('DROPSHOPUSERINFO',JSON.stringify(objs))
  },
  mounted() {
    for (let item of this.langList) {
      if (this.$store.state.langdef == item.langsign) {
        this.langdef = item;
      }
    }
    this.activeLink = window.location.pathname;
    this.setClass();

  },
  watch: {
    $route(to, from) {
      this.activeLink = to.path;
      this.setClass();
    },
  },
  computed: {},
  methods: {
    langChang(item) {
      this.langdef = item;
      this.$store.commit("setLangDef", item.langsign);
      this.$i18n.locale = item.langsign;
      console.log(item.langsign)
      if (item.langsign == 'zh-CN') {
        document.querySelector("meta[name='keywords']").setAttribute('content', '独立站，在线商店，店铺，跨境电商，跨境独立站');
        document.querySelector("meta[name='description']").setAttribute('content', 'taoify多用户在线商店管理系统，支持多语言多货币，包括中国产品库的在线跨境电商在线店铺管理系统，帮助您跨境电商创业或发展现有业务。');
        document.querySelector("title").innerHTML = 'taoify多用户在线商店管理系统';
      } else {
        document.querySelector("meta[name='keywords']").setAttribute('content', 'Independent station, online store, store, cross-border e-commerce, cross-border independent station');
        document.querySelector("meta[name='description']").setAttribute('content', "taoify multi-user online store management system supports multi-language and multi-currency, including an online cross-border e-commerce online store management system for Chinese product libraries, helping you start a cross-border e-commerce business or develop your existing business.");
        document.querySelector("title").innerHTML = 'taoify multi-user online store management system';
      }
      location.reload();
    },
    setClass() {
      // if (this.activeLink == "/sign-up" || this.activeLink == "/success") {
      //   this.classname = "container px-0";
      // } else {
      //   this.classname = "container px-0";
      // }
      if (
        localStorage.getItem("shop") != undefined &&
        localStorage.getItem("shop")
      ) {
        this.obj = JSON.parse(localStorage.getItem("shop"));
      }
    },
    zhuce() {
      this.$router.push({
        path: "/sign-up",
      });
    },
    logout() {

      this.$store.commit("setUSERINFO", null);
      localStorage.removeItem("DROPSHOPUSERINFO");
      localStorage.removeItem("dropshoplocalconfig");
      localStorage.removeItem("SHOPLIST");
      localStorage.removeItem("defaultShopid");
      localStorage.removeItem("DROPSHOPINFO");
      localStorage.removeItem("shop_web_domain");
      this.$router.push("/sign-up");
    },
  },
  destroyed() { },
};
</script>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;

.z-999 {
  z-index: 999;
}

.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}

.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;

      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}

.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;

      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}

.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 50px !important;
  z-index: $z-99;

  .dropdown-ecommerce {
    display: none;
  }

  &.v-app-bar--fixed {
    position: unset;
  }

  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;

    .dropdown-ecommerce {
      display: block;
    }
  }

  @media (max-width: $md) {}
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;

  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.search-bar {
  .search-bar-dropdown {
    position: absolute;
    right: 0;
    border-radius: 22px;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
    box-shadow: none !important;

    @media (max-width: $md) {
      display: none;
    }
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;

  &.open {
    display: block;
    top: 64px;
  }
}

.w23 {
  max-width: 23px;
}
</style>
<style lang='scss'>
.search-btn {
  margin-top: -6px;
  margin-right: -20px;
  height: 34px !important;

  span {
    font-size: 16px;
  }
}
</style>